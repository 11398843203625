import Sidebar from '../Sidebar';
import Footer from '../Footer'
import { Outlet } from 'react-router-dom';
import './index.scss';

const Layout = () => {
    return (
        <div className='App'>
            <Sidebar />
            <div className='page'>
                <span className='tags top-tags'>
                    &lt;Nonso&gt;
                </span>

                <Outlet />

                <span className='tags bottom-tags'>
                &lt;Obikili&gt;
                <br />
                </span>
            </div>
            <Footer />
        </div>
    )
}

export default Layout