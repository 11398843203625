import React, { useEffect, useState } from 'react';
import Parser from 'rss-parser';

const RssFeed = ({ url }) => {
  const [feedData, setFeedData] = useState([]);

  useEffect(() => {
    const parser = new Parser();
    parser.parseURL(url)
      .then((data) => {
        setFeedData(data.items);
      })
      .catch((error) => {
        console.error('Error parsing RSS feed:', error);
      });
  }, [url]);

  return (
    <div>
      <h2>RSS Feed</h2>
      <ul>
        {feedData.map((item, index) => (
          <li key={index}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RssFeed;
