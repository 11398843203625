    import React from 'react';
    import RssFeed from '../RssFeed';
    
    function App() {
      const rssFeedUrl = 'https://cors-anywhere.herokuapp.com/https://nonso2.substack.com/feed'; // Replace with your RSS feed URL
    
      return (
        <div className="App">
          <RssFeed url={rssFeedUrl} />
        </div>
      );
    }
    
    export default App;
    