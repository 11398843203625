import { useEffect, useState } from 'react'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'
import ResearchCards from '../ResearchCards'
import Published from '../../assets/otherdata/published.json'
import Working from '../../assets/otherdata/working.json'
import Works from '../../assets/otherdata/works.json'

const Research = () => {

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return(
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                        strArray={['R', 'e', 's', 'e', 'a', 'r ', 'c', 'h']}
                        idx={15}
                    />
                </h1>
            </div>
            <div className='research-zone'>
            <div className='section-head'>
                
                Published work
                
            </div>
            <div className='employ-cards'>
                
                {Published.map((card) => (
                    <ResearchCards title={card.V1} year={card.V2} coauthor={card.V3} journal={card.V4} type={card.FIELD6} link={card.V6}/>
                    ))
                }
            </div>
            <div className='section-head'>
                
                Working Papers
                
            </div>
            <div className='employ-cards'>
                
                {Working.map((card) => (
                    <ResearchCards title={card.V1} year={card.V2} coauthor={card.V3} journal={card.V4} type={card.FIELD6} link={card.V6}/>
                    ))
                }
            </div>
            <div className='section-head'>
                
                Works in progress
                
            </div>
            <div className='employ-cards'>
                
                {Works.map((card) => (
                    <ResearchCards title={card.V1} year={card.V2} coauthor={card.V3} journal={card.V4} type={card.FIELD6} link={card.V6}/>
                    ))
                }
            </div>
            <div className='section-head'>
                <br />
            </div>   
            </div> 
        </div>
        <Loader type="pacman" />
        </>
    )
}

export default Research