import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import LogoTitle from '../../assets/images/logo-s.png'
import GraphVid from '../../assets/images/output_animation.mp4'
import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import Loader from 'react-loaders';


const Home = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['o', 'n', 's', 'o', ' ', 'O', 'b', 'i', 'k', 'i', 'l', 'i']
    const jobArray = ['E', 'c', 'o', 'n', 'o', 'm', 'i', 's', 't', ',' , ' ', 'A', 'f', 'r', 'i', 'c', 'a', 'n', ',', ' ', 'E', 'x', 't', 'r', 'a', 's', '.']


    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                    <span className={letterClass}>
                        H
                    </span>
                    <span className={`${letterClass} _12`}>i,</span>
                    <br />
                    <span className={`${letterClass} _13`}>I</span>
                    <span className={`${letterClass} _14`}>'m</span>
                <img src={LogoTitle} alt="Nonso" />
                <AnimatedLetters letterClass={letterClass}
                strArray={nameArray}
                idx={15}/>
                <br />
                <AnimatedLetters letterClass={letterClass}
                strArray={jobArray}
                idx={6}/>
                </h1>
                <h2>
                    Current: Development Coordination Officer <br /> @ 
                    <Link className='text-link' to="https://nigeria.un.org/en/about/about-the-resident-coordinator-office" target='_blank'>United Nations</Link>
                </h2>
                <h2>
                    Education: Ph.D. Economics @ SUNY Binghamton
                </h2>
            <div className='btn-grp'>
                <Link to="/Research" className='flat-button'>
                Research
                </Link>
                <Link to="/Blog" className='flat-button'>
                Blog
                </Link>
                <Link to="https://www.dropbox.com/s/izi3pjvz1djqv3t/CV_Nonso_Obikili_new.pdf?dl=0" className='flat-button' target="_blank">
                Download CV
                </Link>
                <Link to="/contact" className='flat-button'>
                Contact Me
                </Link>
                </div>
            </div>
            <div className='vid-zone'>
                <video className='vid-player' src={GraphVid} alt="GDP per Capita against Life Expectancy" autoPlay loop/>
            </div>
        </div>
        <Loader type='pacman' />
        </>
    );
}

export default Home