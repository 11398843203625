import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLinkedin,
    faGithub,
    faYoutube,
    faTwitter,
    faInstagram,
    faMastodon
  } from '@fortawesome/free-brands-svg-icons'
 
const Footer = () => (
    <div className='bottom-bar'>
        <ul>
            <li>
                <a target="_blank" rel='noreferrer' href='https://ng.linkedin.com/in/nonso-obikili-8996b310'>
                    <FontAwesomeIcon icon={faLinkedin} color = "#4d4d4e" />
                </a>
            </li>
            <li>
                <a target="_blank" rel='noreferrer' href='https://github.com/nonso02'>
                    <FontAwesomeIcon icon={faGithub} color = "#4d4d4e" />
                </a>
            </li>
            <li>
                <a target="_blank" rel='noreferrer' href='https://www.youtube.com/channel/UCwGjGtFD0hhATJNs482Q70Q'>
                    <FontAwesomeIcon icon={faYoutube} color = "#4d4d4e" />
                </a>
            </li>
            <li>
                <a target="_blank" rel='noreferrer' href='https://www.instagram.com/nonso.obikili/'>
                    <FontAwesomeIcon icon={faInstagram} color = "#4d4d4e" />
                </a>
            </li>
            <li>
                <a target="_blank" rel='me' href='https://sciences.social/@nonso2'>
                    <FontAwesomeIcon icon={faMastodon} color = "#4d4d4e" />
                </a>
            </li><li>
                <a target="_blank" rel='noreferrer' href='https://www.x.com/nonso2'>
                    <FontAwesomeIcon icon={faTwitter} color = "#4d4d4e" />
                </a>
            </li>
        </ul>
    </div>
)

export default Footer