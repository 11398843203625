import './index.scss'
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLinkedin,
    faTwitter,
    faMastodon
  } from '@fortawesome/free-brands-svg-icons'


const Contact = () => {

    return(
        <>
            <div className='contact-page'>
                <div className='block-text'>
                    <h1>Easiest ways to reach me:</h1>
                </div>
                <div className='btn-grp'>
                <Link to="https://www.x.com/nonso2" className='flat-button' target="_blank">
                    <FontAwesomeIcon icon={faTwitter} color = "#ffd700" /> @nonso2
                </Link>
                <Link to="https://sciences.social/@nonso2" className='flat-button'  target="_blank" rel='me'>
                <FontAwesomeIcon icon={faMastodon} color = "#ffd700" /> @nonso2@sciences.social
                </Link>
                <Link to="https://www.linkedin.com/in/nonso-obikili-8996b310/" className='flat-button' target="_blank">
                <FontAwesomeIcon icon={faLinkedin} color = "#ffd700" /> Nonso Obikili
                </Link>
                </div>
            </div>
            <Loader type='pacman' />
        </>
    )
}

export default Contact