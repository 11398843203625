import './index.scss'

const ResearchCards = ( {title, journal, year, coauthor, link}) => {
    return (
        <div className='emp-card'>
            <h2>{title}</h2>
            <h3>{journal}</h3>
            <p>{coauthor}</p>
            <p>{year}</p>
            <p><a href={link} target="_blank" rel="noreferrer">READ</a></p>
        </div>
    );
}

export default ResearchCards