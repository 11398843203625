import './index.scss'

const EmployCards = ( {employer, position, location, dates, end}) => {
    return (
        <div className='emp-card'>
            <h2>{employer}</h2>
            <h3>{position}</h3>
            <p><b>{location}</b></p>
            <p>{dates} - {end}</p>
        </div>
    );
}

export default EmployCards