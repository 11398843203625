import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout'
import Home from './components/Home';
import Research from './components/Research';
import About from './components/About';
import Contact from './components/Contact'
import Blog from './components/Blog';

function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="About" element={<About />} />
        <Route path="Research" element={<Research />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="Blog" element={<Blog />} />
      </Route>
    </Routes>
    </>
  );
}

export default App;
