import { useEffect, useState } from 'react'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'
import EmployCards from '../EmployCards'
import EmploymentData from '../../assets/otherdata/employment.json'
import JournalistData from '../../assets/otherdata/journalist.json'
import ConsultantData from '../../assets/otherdata/consultant.json'
import LogoS from '../../assets/images/nlogo.png'


const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                        strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']}
                        idx={15}
                    />
                </h1>
                <div className='about-container'>
                    <div className='about-text'>
                    <p>Nonso Obikili is currently the economist at the United Nations Resident Coordinator's Office in Nigeria. He is helping strengthen coordination within the United Nations Country Team and partners to accelerate efforts in meeting the Sustainable Development Goals. Prior to joining the United Nations, Nonso was a policy associate at Economic Research Southern Africa, a non-resident fellow at the Centre for Global Development, a research fellow at Stellenbosch University, and the chief economist at Business Day newspaper in Lagos.</p>
                    <p>He served on the advisory board of the Nigeria Tax Research Network, and the Economic History Society of Southern Africa, and was previously a West Africa Research Association Fellow at American University in Washington DC.</p>
                    <p>His research focuses mostly on African economic history and development, but includes work on taxation and political economy. On economic history, his work focuses on pre-independence institutional development, the factors that shape the characteristics of these institutions and their evolution over time. Other work focuses on the intersection of democracy, voting patterns, and development outcomes.</p>

                    </div>
                    <div className='about-image'>
                        <img src={LogoS} alt="logo" />
                    </div>
                </div>

                <div className='employ-tabs'>
                <h1>
                    Job History
                </h1>
                </div>
            </div>
                
            <div className='section-head'>
                <p>Academic and Policy: <br /></p>
            </div>
            <div className='employ-cards'>
                
                {EmploymentData.map((card) => (
                    <EmployCards employer={card.FIELD2} position={card.FIELD1} location={card.FIELD3} dates={card.FIELD4} end={card.FIELD5}/>
                    ))
                }
            </div>
            <div className='section-head'>
                <p>Media: <br /></p>
            </div>
            <div className='employ-cards'>
                
                {JournalistData.map((card) => (
                    <EmployCards employer={card.FIELD2} position={card.FIELD1} location={card.FIELD3} dates={card.FIELD4} end={card.FIELD5}/>
                    ))
                }
            </div>
            <div className='section-head'>
                <p>Consulting: <br /></p>
            </div>
            <div className='employ-cards'>
                
                {ConsultantData.map((card) => (
                    <EmployCards employer={card.FIELD2} position={card.FIELD1} location={card.FIELD3} dates={card.FIELD4} end={card.FIELD5}/>
                    ))
                }
            </div>
            <div className='section-head'>
                <p><br /></p>
            </div>
        </div>
        <Loader type="pacman" />
        </>
    )
}

export default About