import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import LogoS from '../../assets/images/nlogo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import{ faHome, faUser, faEnvelope, faBook, faBlog } from '@fortawesome/free-solid-svg-icons'


const Sidebar = () => (
    <div className='nav-bar'>
        <Link className='logo' to='/'>
            <img src={LogoS} alt="logo" />
        </Link>
        <nav>
            <NavLink exact="true" activeclassname="active" to ="/">
                <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
            </NavLink>
            <NavLink exact="true" activeclassname="active" className="about-link" to ="/about">
                <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
            </NavLink>
            <NavLink exact="true" activeclassname="active" className="research-link" to ="/research">
                <FontAwesomeIcon icon={faBook} color="#4d4d4e" />
            </NavLink>
            <NavLink exact="true" activeclassname="active" className="blog-link" to ="/blog">
                <FontAwesomeIcon icon={faBlog} color="#4d4d4e" />
            </NavLink>
            <NavLink exact="true" activeclassname="active" className="contact-link" to ="/contact">
                <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
            </NavLink>
            <button className='hamburger'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5'
                    viewBox='0 0 20 20'
                    fill='#282c34'
                    >
                        <path 
                        fillRule='evenodd'
                        d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z'
                        clipRule="evenodd"
                        />
                    </svg>
            </button>
        </nav>
    </div>
)

export default Sidebar